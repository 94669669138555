import type {MantineThemeOverride, ButtonStylesParams, TextStylesParams, AlertStylesParams} from '@mantine/core';

import {getBgColor} from './utils/getBgColor';
import {getTextColor} from './utils/getTextColor';
import {getBorderColor} from './utils/getBorderColor';
import {getObserverColor} from './utils/getObserverColor';
import {colors} from './colors';

const DEFAULT_FONT =
  'Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji';

export const theme: MantineThemeOverride = {
  defaultRadius: 2,
  colors,
  primaryColor: 'indigo',
  primaryShade: 6,
  fontFamily: DEFAULT_FONT,
  headings: {
    fontFamily: DEFAULT_FONT,
  },
  fontSizes: {
    xs: 12,
    sm: 14,
    md: 16,
    lg: 18,
    xl: 22,
  },
  other: {
    getTextColor,
    getBgColor,
    getBorderColor,
    getObserverColor,
  },
  components: {
    Text: {
      styles: (theme, params: TextStylesParams) => {
        if (params.color === 'dimmed') {
          return {
            root: {
              color: getTextColor(theme, 'text-2'),
            },
          };
        }
        if (params.variant === 'link' && !params.color) {
          return {
            root: {
              color: theme.colorScheme === 'light' ? theme.colors.indigo[6] : theme.colors.indigo[4],
            },
          };
        }
        return {
          root: {},
        };
      },
    },
    Button: {
      styles: (theme, params: ButtonStylesParams) => {
        const accent = theme.colors['accent-1'];
        if (params.variant === 'outline' && !params.color) {
          return {
            root: {
              color: theme.colorScheme === 'dark' ? accent[1] : accent[5],
              borderColor: theme.colorScheme === 'dark' ? accent[3] : accent[5],
              '&:hover': {
                backgroundColor:
                  theme.colorScheme === 'dark' ? theme.fn.rgba(accent[1], 0.1) : theme.fn.rgba(accent[1], 0.2),
              },
            },
          };
        }

        return {root: {}};
      },
    },
    Title: {
      styles: theme => ({
        root: {
          color: getTextColor(theme, 'text-0'),
        },
      }),
    },
    TextInput: {
      styles: theme => ({
        input: {
          borderColor: getBorderColor(theme, 'border-2'),
          backgroundColor: getBgColor(theme, 'bg-1'),
          '&:focus': {
            borderColor: getBorderColor(theme, 'border-0'),
          },
          '&:hover': {
            borderColor: getBorderColor(theme, 'border-0'),
          },
          color: getTextColor(theme, 'text-0'),
          fontWeight: 500,
        },
      }),
    },
    Input: {
      styles: theme => ({
        input: {
          borderColor: getBorderColor(theme, 'border-2'),
          backgroundColor: getBgColor(theme, 'bg-2'),
          '&:focus': {
            borderColor: getBorderColor(theme, 'border-0'),
          },
          '&:hover': {
            borderColor: getBorderColor(theme, 'border-0'),
          },
          color: getTextColor(theme, 'text-1'),
          fontWeight: 500,
        },
      }),
    },
    Select: {
      styles: theme => ({
        input: {
          borderColor: getBorderColor(theme, 'border-2'),
          backgroundColor: getBgColor(theme, 'bg-1'),
          cursor: 'pointer',
          '&:focus': {
            borderColor: getBorderColor(theme, 'border-0'),
          },
          '&:hover': {
            borderColor: getBorderColor(theme, 'border-0'),
          },

          color: getTextColor(theme, 'text-1'),
          fontWeight: 500,
        },
        // to have cursor pointer even above right section
        rightSection: {pointerEvents: 'none'},
        item: {
          fontWeight: 500,

          '&[data-selected]': {
            '&, &:hover': {
              // styles applied to selected item in dropdown
              backgroundColor: theme.colors.indigo[9],
              color: theme.white,
            },
          },

          // applies styles to hovered item (with mouse or keyboard)
          '&[data-hovered]': {},
        },
      }),
    },
    MultiSelect: {
      styles: theme => ({
        input: {
          borderColor: getBorderColor(theme, 'border-2'),
          backgroundColor: getBgColor(theme, 'bg-1'),
          cursor: 'pointer',
          '&:focus': {
            borderColor: getBorderColor(theme, 'border-0'),
          },
          '&:hover': {
            borderColor: getBorderColor(theme, 'border-0'),
          },

          color: getTextColor(theme, 'text-1'),
          fontWeight: 500,
        },
        // to have cursor pointer even above right section
        rightSection: {pointerEvents: 'none'},
        item: {
          fontWeight: 500,

          '&[data-selected]': {
            '&, &:hover': {
              // styles applied to selected item in dropdown
              backgroundColor: theme.colors.indigo[9],
              color: theme.white,
            },
          },

          // applies styles to hovered item (with mouse or keyboard)
          '&[data-hovered]': {},
        },
      }),
    },
    Tooltip: {
      styles: theme => ({
        tooltip: {
          backgroundColor: theme.black,
          color: theme.white,
        },
      }),
    },
    Checkbox: {
      styles: theme => ({
        root: {
          lineHeight: 0,
        },
        label: {
          color: getTextColor(theme, 'text-1'),
          fontWeight: 500,
          cursor: 'pointer',
        },
        input: {
          cursor: 'pointer',
          backgroundColor: getBgColor(theme, 'bg-1'),
        },
      }),
    },
    Alert: {
      styles: (theme, params: AlertStylesParams) => {
        if (params.variant === 'light') {
          return {
            message: {
              fontWeight: 500,
              color: 'inherit',
            },
            icon: {
              marginTop: 0,
              marginRight: theme.spacing.xs,
            },
          };
        }
        return {
          message: {},
          icon: {},
        };
      },
    },
    Divider: {
      styles: theme => ({
        root: {
          color: theme.other.getBorderColor(theme, 'border-2'),
        },
      }),
    },
  },
  globalStyles(theme) {
    return {
      body: {
        ...theme.fn.fontStyles(),
        lineHeight: theme.lineHeight,
      },
    };
  },
};
