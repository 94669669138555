import {createEmotionCache, MantineProvider} from '@mantine/core';
import {StylesPlaceholder} from '@mantine/remix';
import {Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration} from '@remix-run/react';
import type {ShouldRevalidateFunction} from '@remix-run/react';
import {withSentry} from '@sentry/remix';
import {assets, chains} from 'chain-registry';
import {json, useLoaderData} from '~/features/serialization';
import {getColorSchemeSession, theme} from '~/features/ui';
import {WalletProvider, wallets} from '~/features/wallet';
import {supportedChains} from '~/features/supported-chains';
import type {LinksFunction, LoaderArgs, MetaFunction} from '@remix-run/node';
import type {Chain} from '@chain-registry/types';
import {Toaster} from 'sonner';

export const emotionCache = createEmotionCache({key: 'mantine'});

export const links: LinksFunction = () => {
  return [
    {rel: 'preconnect', href: 'https://fonts.googleapis.com'},
    {
      rel: 'preconnect',
      href: 'https://fonts.gstatic.com',
      crossOrigin: 'anonymous',
    },
    {
      rel: 'stylesheet',
      href: 'https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;900&display=swap',
    },
  ];
};

export const meta: MetaFunction = () => ({
  charset: 'utf-8',
  title: 'Smart Delegation App',
  viewport: 'width=device-width,initial-scale=1',
  language: 'English',
});

export async function loader({request}: LoaderArgs) {
  const colorSchemeSession = await getColorSchemeSession(request);
  const colorScheme = colorSchemeSession.getTheme() ?? 'dark';

  return json({
    env: {
      APP_ENV: process.env.APP_ENV ?? process.env.NODE_ENV,
      APP_VERSION: process.env.APP_VERSION,
      SENTRY_DSN: process.env.SENTRY_DSN,
    },
    colorScheme,
  });
}

export const shouldRevalidate: ShouldRevalidateFunction = ({formAction, defaultShouldRevalidate}) => {
  return formAction?.includes('action/color-scheme') ?? defaultShouldRevalidate;
};

function App() {
  const {env, colorScheme} = useLoaderData<typeof loader>();

  const supportedChainIds = new Set(supportedChains.map(({chainId}) => chainId));
  const usedChains: Chain[] = [
    ...chains.filter(({chain_id}) => supportedChainIds.has(chain_id)),
    // dydx is not in chain registry so we have to add it manualy
    {
      bech32_prefix: 'dydx',
      chain_id: 'dydx-testnet-1',
      chain_name: 'dydxtestnet',
      network_type: 'testnet',
      pretty_name: 'dydx',
      slip44: 118,
      status: 'live',
    },
  ].filter(chain => env.APP_ENV !== 'production' || chain.network_type !== 'testnet');

  return (
    <MantineProvider
      emotionCache={emotionCache}
      withGlobalStyles
      withNormalizeCSS
      theme={{
        colorScheme,
        ...theme,
      }}
    >
      <html lang="en">
        <head>
          <Meta />
          <Links />
          <StylesPlaceholder />
        </head>
        <body>
          <WalletProvider chains={usedChains} assetLists={assets} wallets={wallets}>
            <Outlet />
            <ScrollRestoration />
            <script
              dangerouslySetInnerHTML={{
                __html: `window.ENV = ${JSON.stringify(env)}`,
              }}
            />
            <Scripts />
            <LiveReload />
          </WalletProvider>
          <Toaster richColors closeButton visibleToasts={5} />
        </body>
      </html>
    </MantineProvider>
  );
}

export {ErrorBoundary} from './root.boundaries';

export default withSentry(App);
