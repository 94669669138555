import type {MantineThemeOverride} from '@mantine/core';
import type {TextColors, BgColors, BorderColors, ObserverColors} from './types';

export const textColors: TextColors = {
  light: {
    'text-0': '#000000',
    'text-1': '#343A40',
    'text-2': '#747c84',
    'text-3': '#B1B8C1',
    'text-4': '#D3D7DD',
  },
  dark: {
    'text-0': '#FFFFFF',
    'text-1': '#BEC0C7',
    'text-2': '#A0A2A9',
    'text-3': '#6e727b',
    'text-4': '#61656E',
  },
};

export const bgColors: BgColors = {
  light: {
    'bg-0': '#FFFFFF',
    'bg-1': '#F9F9FA',
    'bg-2': '#EEF0F3',
    'bg-3': '#E5E8EA',
  },
  dark: {
    'bg-0': '#151619',
    'bg-1': '#25262B',
    'bg-2': '#2F3034',
    'bg-3': '#37383E',
  },
};

export const borderColors: BorderColors = {
  light: {
    'border-0': '#B0B2B5',
    'border-1': '#D8DBE1',
    'border-2': '#E8EBEE',
    'border-3': '#F3F5F7',
  },
  dark: {
    'border-0': '#6E727B',
    'border-1': '#3E4148',
    'border-2': '#2E3033',
    'border-3': '#1F2124',
  },
};

export const observerColors: ObserverColors = {
  light: {
    'observer-0': '#EC009C',
    'observer-1': '#EC009C',
    'observer-2': '#D3008B',
    'observer-3': '#C20281',
    'observer-4': '#B60078',
  },
  dark: {
    'observer-0': '#CE44ff',
    'observer-1': '#AE00EC',
    'observer-2': '#9F01D7',
    'observer-3': '#9102C4',
    'observer-4': '#7D01A9',
  },
};


/**
 * Overrides for default mantine colors
 */
export const colors: MantineThemeOverride['colors'] = {
  'accent-0': [
    '#eafaf8', // 0
    '#c1f0ea', // 1
    '#98e6dc', // 2
    '#6fdcce', // 3
    '#45c3b2', // 4
    '#2db9a6', // 5
    '#2da494', // 6
    '#19675c', // 7
    '#0f3e37', // 8
    '#051512', // 9
  ],
  'accent-1': [
    '#eaedfa', // 0
    '#c1c9f0', // 1
    '#98a5e6', // 2
    '#6f81dc', // 3
    '#466ad3', // 4
    '#2d43b9', // 5
    '#233490', // 6
    '#192567', // 7
    '#0f163e', // 8
    '#050715', // 9
  ],
  'accent-2': [
    '#f5e6ff', // 0
    '#e2b3fe', // 1
    '#ce81fe', // 2
    '#bb4efd', // 3
    '#a71cfd', // 4
    '#8e02e3', // 5
    '#6e02b1', // 6
    '#4f017e', // 7
    '#2f014c', // 8
    '#100019', // 9
  ],
};
